import React from "react"
import styled from "styled-components"
import { fadeIn } from "../styles/animations"

const Container = styled.div`
  width: 100%;
  height: 40vh;
  min-height: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: -1;
  align-items: center;
  padding-top: 60px;

  @media (min-width: 650px) {
    height: 60vh;
    padding-top: 0;
    min-height: 60vh;
  }
`
const BG = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  margin: 0;
  z-index: -1;
  left: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
  padding-top: 60px;

  @media (min-width: 650px) {
    padding-top: 0;
  }
`
const Logo = styled.img`
  margin: 0;
  z-index: 1;
  align-self: center;
  justify-self: center;
  width: 80%;
  max-width: 500px;
`

const PageHeader = props => (
  <Container>
    <BG src={props.image} />
    {!props.noLogo && <Logo src={require("../images/logo_white.png")} />}
  </Container>
)

export default PageHeader
