import styled from 'styled-components'

export const DentistryHeaderCard = styled.div`
max-width: 700px;
width: 90%;
z-index: 0;
padding: 30px;
border-radius: 3px;
margin: 0 auto;
margin-top: -8%;
margin-bottom: 30px;
background: white;
box-shadow: 0 10px 19px rgba(203, 203, 203, 0.5);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
`