import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Colors } from "../colors/Colors"

const Container = styled.div`
  align-self: flex-start;
  font-family: "Articulat";
  font-weight: 500;
  font-size: 16px;
`

const BreadCrumbs = props => (
  <Container>
    <span>
      <Link style={{ color: Colors.gold }} to="/dentistry">
        Dentistry
      </Link>{" "}
      / {props.page}
    </span>
  </Container>
)

export default BreadCrumbs
