import React, { Component } from "react"
import styled from "styled-components"
import tooth from "../images/logo_alt.png"

const ServiceItem = props => (
  <Container>
    <Icon src={tooth} />
    <InfoContainer>
      <Title>{props.title}</Title>
      <Copy>
        {props.short_description}
      </Copy>
    </InfoContainer>
  </Container>
)

export default ServiceItem

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 50px 0;
`
const Icon = styled.img`
  width: 40px;
  height: auto;
  object-fit: contain;
  margin: 0 40px 0 0;
`
const InfoContainer = styled.div``
const Title = styled.h3`
  font-weight: 600;
    margin: 0 0 10px 0;
    font-size: 18px;
    text-transform: uppercase;
    color: rgb(188,158,94);
`
const Copy = styled.p`
  margin: 0;
  color: #333333;
  font-size: 16px;
`
