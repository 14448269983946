import React, { Component } from 'react';
import styled from 'styled-components'
import { DentistryHeaderCard } from './dentistry-header-card';
import Lottie from "lottie-react-web"
import animation from "../animations/972-done.json"
const Title = styled.h1`
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -1px;
  text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
`
const Subtitle = styled.p`
  margin: 0;
  max-width: 500px;
    font-size: 17px;
    line-height: 140%;
    color: #2d2d2d;
`

const DentistryHeader = props => (
  <DentistryHeaderCard>

    <Title>{props.title}</Title>
    <Subtitle>{props.subtitle}</Subtitle>
  </DentistryHeaderCard>
)

export default DentistryHeader